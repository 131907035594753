import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';


const routes: Routes = [
  /*{
    path: '',
    loadChildren: () => import('./pages/welcome/welcome.module').then(mod => mod.WelcomeModule),
    canActivate: [AuthGuardService]
  },*/
  {
    path: 'elenco',
    loadChildren: () => import('./pages/home/home.module').then(mod => mod.HomeModule),
  },
  {
    path: 'interaction/game/timeline',
    redirectTo: 'interaction-custom/custom/timeline'
  },{
    path: 'interaction/game/risultati',
    loadChildren: () => import('./pages/punteggio/punteggio.module').then(mod => mod.PunteggioModule)
  },
  {
    path: 'interaction',
    loadChildren: () => import('./pages/interaction-page/interaction-page.module').then(mod => mod.InteractionPageModule)
  },
  {
    path: 'interaction-custom',
    loadChildren: () => import('./pages/interaction-custom/interaction-custom.module').then(mod => mod.InteractionCustomModule)
  },
  {
    path: 'interaction-complete',
    loadChildren: () => import('./pages/interaction-complete/interaction-complete.module').then(mod => mod.InteractionCompleteModule)
  },
  {
    path: 'debug',
    loadChildren: () => import('./pages/debug/debug.module').then(mod => mod.DebugModule)
  },
  {
    path:'**',
    redirectTo: 'interaction/game/risultati'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
