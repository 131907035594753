import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  private headerToSet = new BehaviorSubject('home');
  currentMessage = this.headerToSet.asObservable();


  constructor(
    public router: Router,
  ) {
  }

  setHeader(message: string) {
    this.headerToSet.next(message);
  }


}
