export const environment = {
  production: false,
  application:
    {
      name: 'Roccavione',
      version: '1.0',
    },

  frontendEndpoint: 'https://roccavione.heritage.srl/',
  apiUrl: 'https://roccavione.heritage.srl/'
};
