<header><div class="container-fluid">
  <div class="row pt-3 pb-3">

    <div class="col-8 offset-2 text-right col-md-3 text-center m-auto">
      <img src="./assets/images/default/roccavione_logo.png" class="img-fluid qr_logo" alt="">
      <span class="d-inline-block text-left testoLogo">Comune <br>di Roccavione</span>
    </div>
  </div>
</div>
</header>

<!---->

<!--
<header class="border-bottom" *ngIf="!homeHeader">
  <div class="container-fluid">
  <div class="row pt-3 pb-3">
    <div class="col-7 mt-1">
      <a [routerLink]="['home']" class="text-primary d-flex flex-row align-items-center">
        <img src="./assets/images/default/back.svg" class="img-fluid mr-1 icon-back" alt="">
        <span>Torna all'elenco</span>
      </a>
    </div>
    <div class="col-5 text-right">
      <img src="./assets/images/default/Logo_Sfida.jpg" class="img-fluid" alt="" class="qr_logo">
    </div>
  </div>
</div>
</header>
-->
