import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Gioca con Roccavione';
  selectLanguage = true;

  constructor() {
    if (localStorage.getItem('l')) {
      console.log('lingua impostata');
    } else {
      this.selectLanguage = false;
      console.log('lingua NON impostata');
    }
  }

  onSelectLanguage(lang) {
    localStorage.setItem('l', lang);
    location.reload();
  }
}
