<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<div class="selectLanguage" *ngIf="!selectLanguage">
  <img src="assets/images/languages/roccavione-intro.jpg" class="img-fluid" alt="">
  <div class="container pt-5">
    <div class="row mt-1">
      <div class="col-6 m-auto"><img src="assets/images/default/roccavione_logo.png" alt="" class="img-fluid"></div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-center">
        <h3 id="hi" class=""><span class="animatedTitle">
  </span></h3>
        <h4><span class="animated"></span></h4>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-3 offset-3"><img (click)="onSelectLanguage('it')" src="assets/images/languages/it.png"
                                       class="img-fluid" alt=""></div>
      <div class="col-3"><img (click)="onSelectLanguage('en')" src="assets/images/languages/uk.png" class="img-fluid"
                              alt=""></div>
    </div>
  </div>
</div>
