import {Component, Input, OnInit} from '@angular/core';
import { CommonsService } from 'src/app/services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  homeHeader;

  constructor(
    private common: CommonsService) {

  }

  ngOnInit() {
    this.common.currentMessage.subscribe(activePage => {

      this.homeHeader = activePage === 'home';
    });

  }

}
